.summary {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  margin-top: 0rem;
  position: relative;
  background-color: #rgba(255,255,255,0.1);
  color: white;
  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.5);
}

.summary h2 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px !important;
}
p {
  font-weight: 500;
  margin: 10px auto;
}
