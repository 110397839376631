.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: rgba(255,255,255,0.8);
  color: white;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10000000000000000000;
}
.logoImg{
  width: 70px;
  height: 70px;

}
.logoImg img{
  width: 100%;
  height: 100%;
}
.link{
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.link:hover{
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.25);
}
.links{
  display: flex;
}
.main-image {
  width: 100%;
  z-index: 0;
  height: 580px;
  overflow: hidden;
}
.main-image img {
  width: 120%;
  height: 100%;
  object-fit: cover;
  transform:   translateY(0rem) translateX(-5rem);
}
.displayNone{
  display: none;
}
@media screen and (max-width:768px) {
  .links{
    display: flex;
  }
  .links .link{
    font-size: 12px;
    padding: 5px;
    margin: 7px;
    /* line-height: 12px; */
  }
  .logoImg{
    width: 40px;
    height: 40px;
  }
  .header {
    height: 4rem;
  }

}