.meals {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  animation: meals-appear 1s ease-out forwards;
}

.meals ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-image {
  width: 100vw;
  /* height: 35rem; */
  z-index: 0;
  overflow: hidden;
}

.main-image img {
  width: 120%;
  height: 100%;
  object-fit: cover;
  /* transform:  translateY(3rem) translateX(10rem); */
}

@keyframes meals-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
